import React from 'react'
import { SidebarContent } from './modal'
import logoOsd from '../assets/images/openseadragon-logo_n.png'
import nasa from '../assets/images/nasa.png'
import libvips from '../assets/images/libvips.jpg'
import d3Lib from '../assets/images/d3.svg'

export default function Services({ isVisible }) {


    return (
        <SidebarContent isVisible={isVisible}>
            <h3>About FL4 </h3>
            <div className="services">
                <a className="service" href="/fl4" target="_blank">
                    <span className="iconify" data-icon="mdi-transit-detour" data-inline="false"></span>
                    <div>
                        <b>Take the Tour </b>
                        <div>
                            FL4 Guide
                        </div>
                    </div>
                </a>
                <div className="service">
                    <span className="iconify" data-icon="mdi-youtube" data-inline="false"></span>
                    <div>
                        <b>Watch FL4 in action</b>
                        {/* <div>My layouts will work on any device, big or small.</div> */}
                    </div>
                </div>

                {/* <div className="service">

                    <span className="iconify" data-icon="mdi-lightbulb-outline" data-inline="false"></span>

                    <div>
                        <b>Technologies</b>
                        <div>Strong preference for easy to use, intuitive UX/UI.</div>
                    </div>
                </div> */}

                <div className="service">
                    <span className="iconify" data-icon="mdi-semantic-web" data-inline="false"></span>
                    <div>
                        <b>Technologies</b>
                        <br />
                        <div className="techlogos">
                            <div>
                                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K"
                                    alt="ReactJS" title="React JS" width="40px" /> <span>React</span>
                            </div>
                            <div>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Electron_Software_Framework_Logo.svg/100px-Electron_Software_Framework_Logo.svg.png"
                                    alt="Electron" title="Electron JS" width="40px" /><span>Electron</span>
                            </div>
                            <div>
                                <img src={logoOsd}
                                    // <img src="https://blogs.library.duke.edu/bitstreams/files/2015/11/openseadragon-logo.png"
                                    alt="OpenSeaDragon" title="OpenSeaDragon" height="40px" />
                            </div>
                            <div>
                                <img src={nasa} alt="NASA’s CFITSIO library" title="NASA’s CFITSIO library" height="40px" /> <span>NASA’s CFITSIO</span>
                            </div>

                            <div>
                                <img src={libvips} alt="Libvips" title="Libvips" height="40px" />
                                {/* <span>Processing </span> */}

                            </div>
                            <div>
                                <img src={d3Lib} alt="D3 JS" title="D3 JS" height="40px" />                            <span>D3.js</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SidebarContent>
    )
}
