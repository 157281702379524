import winIcon from '../assets/images/dw_win.png'
import linuxIcon from '../assets/images/dw_linux.png'
import macIcon from '../assets/images/dw_mac.png'

const navInfo = window.navigator.appVersion.toLowerCase();

let info = { icon: '', urlDownload: '#' };

export const getInfo = () => {
    if (navInfo.indexOf('win') !== -1) {
        info = { icon: winIcon, urlDownload: process.env.REACT_APP_WINDOWS_URL ?? '#' };
    }
    else if (navInfo.indexOf('linux') !== -1) {

        info = { icon: linuxIcon, urlDownload: process.env.REACT_APP_LINUX_URL ?? '#' };
    }
    else if (navInfo.indexOf('mac') !== -1) {

        info = { icon: macIcon, urlDownload: process.env.REACT_APP_MAC_URL ?? '#' };
    }
    return info
}
