import React from 'react'
import './hero.scss'

const HeroButton = ({ primary, text, icon, link = "#", ...rest }) => {

    return (
        <a href={link} className="Button" data-primary={primary} {...rest}> {icon && <img src={icon} alt='OS Icon' width='40' />} {text}</a>
    );

}

export default HeroButton