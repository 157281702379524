
import './assets/css/App.scss';
import Slider from "react-slick";
import HeroButton from './HeroButton';
import { useEffect, useState } from 'react'
import Services from './components/Services';
// import Payments from './components/Payments';
import { getInfo } from './utils/detectOS'
import { settings } from './utils/configSlick'

import bitpointer from './assets/images/bitpointer.svg'
import ImageCard from './components/ImageCard';

function App() {

  const productName = 'FITS Liberator '

  const { icon, urlDownload } = getInfo()
  
  // const productName = 'NOIRLab/IPAC/ESA/STScI/CfA FITS Liberator '
  // const lightName = 'FITS Liberator '

  const [showServices, setShowServices] = useState(false)
  const [showPayments, setShowPayments] = useState(false)

  const onClickService = () => {
    // setShowPayments(false)
    // setShowServices(!showServices)
  }
  const onClickPayment = () => {
    // setShowServices(false)
    // setShowPayments(!showPayments)
    setShowPayments(false)
    setShowServices(!showServices)
  }
  const click = e => {
    if (document.querySelector('.sidebar-content').contains(e.target)) {
      // Clicked in box      

    } else {
      // Clicked outside the box
      setShowServices(false)
    }
  }
  useEffect(() => {


    window.addEventListener('click', click, true);
    return () => {
      window.removeEventListener('click', click);
    }
  })

  return (
    <div className="App">
      <header className="header">

        {/* <Animation />  */}
        {/* <span><h3>bitOne</h3></span> */}
      </header>
      <section>
        <h2 style={{ paddingLeft: '2em' }}>{productName}</h2>
        <div className="list-proj">
          <div id="hero" className="Hero" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80) cover' }}>
            <div className="content">
              <h2 >FL4</h2>
              <p>The release of version 4 of the popular NOIRLab/IPAC/ESA/STScI/CfA FITS Liberator image processing software has meant that it is even easier and faster to create colour images using raw observations from a range of telescopes, including the NASA/ESA Hubble Space Telescope, NASA’s Spitzer Space Telescope, ESO’s Very Large Telescope and ESA’s XMM-Newton Telescope.</p>
              <div className="button-wrapper">
                <HeroButton primary={true} text="Download" icon={icon} onClick={onClickService} link={urlDownload} />
                <HeroButton primary={false} text="More +" onClick={onClickPayment} />
              </div>
            </div>
            <div className="overlay"></div>
          </div>
          <div className="content-slider">
            <Slider {...settings}>
              {[1, 2, 3, 4, 5, 6, 7].map(x => {
                const url = `https://bitpointer.co/fl/image${x}.png`
                return <ImageCard url={url} key={x} />
              })}
            </Slider>
          </div>

          {/* <side>
            <nav>
              <li>Take tour</li>
              <li>watch FL4 videos</li>
              <li>Technologies</li>
            </nav>
          </side> */}

          <div className="sponsor-team">
            <h2> Sponsors </h2>

            <div class="sponsors-grid slick-track" index="0">
              <div class="">
                <img src="http://www.esa.int/extension/pillars/design/pillars/images/ESA_Logo.svg" alt="" width="100px" />
              </div>
              <div class="">
                <img src={bitpointer} alt="" width="100px" />

              </div>
              <div class="">
                <img src="https://noirlab.edu/public/static/images/logos/noirlab-nsf.84fba631858b.svg" alt="" width="100px" />
              </div>
              <div class="">
                <img src="img/client-4.png" alt="" />
              </div>
              <div class="">
                <img src="img/client-5.png" alt="" />
              </div>
              <div class="">
                <img src="img/client-6.png" alt="" />
              </div>
            </div>
            {/* <div>development team</div> */}
          </div>
          <canvas id="webgl" style={{ maxHeight: '70%', maxWidth: '100%' }}></canvas>
        </div>
      </section>

      <footer>
        <ul>
          <li><a href={`https://en.wikipedia.org/wiki/FITS_Liberator`} target="_blank" rel="noreferrer" ><b>Wikipedia</b>  </a> </li>
          <li><a href={`https://esahubble.org/projects/fits_liberator/`} target="_blank" rel="noreferrer" ><b>ESAHubble</b>  </a> </li>
          <li><a href={`https://noirlab.edu/public/es/products/applications/app001/`} target="_blank" rel="noreferrer" ><b>NOIRLab </b>  </a></li>
        </ul>
      </footer>

      <Services isVisible={showServices} />
      {/* <Payments isVisible={showPayments} /> */}
    </div >
  );
}

export default App;
